@import "src/scss/module";

.JobCard {
  transition: transform 0.25s;
  text-align: left;
  width: 325px;
  max-width: 100%;
  min-width: 295px;

  &:hover {
    transform: translateY(-0.5rem);
  }

  &__wrapper {
    position: relative;
    clip-path: #{$hexagon-clip-path-alt};
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  &:global(:not(.featured)) &__wrapper {
    &:before {
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 100%;
      background-color: #{$white};
      z-index: 1;
      transition: clip-path 0.15s;
      clip-path: polygon(
        0 50%,
        50% 50%,
        23% 13%,
        50% 0,
        77% 13%,
        50% 50%,
        100% 50%,
        100% 75%,
        80% 86%,
        50% 50%,
        20% 86%,
        0 75%
      );
    }
  }

  &:global(:not(.featured)):hover &__wrapper {
    &:before {
      clip-path: polygon(
        0 50%,
        0 25%,
        23% 13%,
        50% 0,
        77% 13%,
        100% 25%,
        100% 50%,
        100% 75%,
        80% 86%,
        50% 100%,
        20% 86%,
        0 75%
      );
    }
  }
  &:global(.featured) {
    background-image: #{$gradient-green};

    .card-header {
      i {
        background-color: red !important; // Set background color to red
      }
    }
  }

  &:global(.featured) &__wrapper {
    clip-path: #{$hexagon-clip-path-alt};
    background-image: #{$gradient-cyan};
    padding: 0.1rem;
    * {
      color: #000000;
    }
    i {
      background-image: #{$gradient-cyan};
    }
  }

  &__inner {
    position: relative;
    clip-path: #{$hexagon-clip-path_alt};
    width: 100%;
    height: 100%;
    background-color: #{$navy};
    z-index: 3;
  }

  :global {
    .card-header {
      padding-top: 1rem;
      border: 0;
      text-align: center;
      color: #{$white};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      height: 70px;

      i {
        display: block;
        width: 12px;
        height: 14px;
        clip-path: #{$hexagon-clip-path_alt};
        background-image: #{$gradient-green};
      }
    }

    .card-body {
      color: #{$white};
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      i {
        font-style: normal;
        background-image: #{$gradient-cyan-270};
        color: #{$dark};
        padding: 0.25rem 0.5rem;
      }

      address {
        margin-bottom: 0;
      }
    }

    .card-footer {
      text-align: center;
      padding-bottom: 2.5rem;
      border: 0;

      span {
        color: #{$white};
        font-weight: #{$font-weight-bold};
      }
    }
  }
}

.JobCard__featured {
  background-image: linear-gradient(#3ac96b, #89e894);
}
